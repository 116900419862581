


















import { Vue, Component, Prop } from "vue-property-decorator";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";

@Component({
  components: {
    "primary-button": PrimaryButton
  }
})
export default class ErrorTemplate extends Vue {
  @Prop() errorTitle?: string;
  @Prop() errorMessage?: string;
  @Prop() buttonType?: string;

  private goToHome() {
    checkCurrentRouteAndRedirect(this.$router, "/");
  }

  async logout() {
    await this.$auth.signOut({
      postLogoutRedirectUri: window.location.origin + "/logout"
    });
    // clear tokens
    this.$auth.tokenManager.clear();
    // update launch-darkly for non-authenticated user
    await this.$launchDarkly.identify({
      anonymous: true
    });
  }
}
